import React, { useEffect } from 'react';
import PropTypes, { oneOfType, shape, string, bool, arrayOf, array } from 'prop-types';

import Loader from '../../molecules/Loader';

import { useUiStore, useLangStore } from '../../../store';

import SEO from '../../molecules/seo';

import Header from '../../molecules/Header';
import { capitaliseFirstLetter } from '../../../helpers/string';

import '../../../assets/styles/font-face.css';
import '../../../assets/styles/layout.css';
import Modal from '../../molecules/Modal';
import SettingsModal from '../../molecules/SettingsModal';

const Page = ({ children, title = '', description, showTitle = true, metaImg, backTo, data, noindex }) => {
  const { loaderFinished, setPath } = useUiStore(store => store);
  const currentLang = useLangStore(store => store.currentLang);
  const { settingsModalOpen, closeSettingsModal } = useUiStore(store => store);

  useEffect(() => {
    if (data?.location) {
      setPath(data.location.pathname);
    } else {
      setPath(null);
    }
  }, []);

  const pageTitle = typeof title === 'object' && title[currentLang] ? title[currentLang] : title;

  return (
    <>
      <SEO
        title={pageTitle.toLowerCase() === 'index' ? pageTitle : capitaliseFirstLetter(pageTitle)}
        description={description}
        metaImg={metaImg}
        noindex={noindex}
      />
      {loaderFinished ? (
        <>
          <Header title={showTitle ? capitaliseFirstLetter(pageTitle) : ''} backTo={backTo} />
          <SettingsModal isOpen={settingsModalOpen} close={closeSettingsModal} />
          {children}
        </>
      ) : (
        null
      )}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: oneOfType([
    string,
    shape({
      en: string,
      fr: string,
    }),
  ]),
  description: string,
  metaImg: string,
  showTitle: bool,
  data: oneOfType([
    array,
    shape({
      pathname: string,
    }),
  ]),
};

export default Page;
