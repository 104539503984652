import React from 'react';

import { useLangStore } from '../../../store';
import { Label, Span } from '../../atoms/Text';

const LangSelector = props => {
  const setLang = useLangStore(store => store.setCurrentLang);
  const currentLang = useLangStore(store => store.currentLang);
  const isEn = currentLang === 'en';

  return (
    <div {...props}>
      <Label
        weight={isEn ? '700' : '400'}
        underline={isEn}
        onClick={() => (!isEn ? setLang('en') : null)}
        selectable={false}
        data-hoverable={!isEn}
        css={`
          cursor: none;
        `}
      >
        EN
      </Label>
      <Span
        selectable={false}
        css={`
          cursor: none;
        `}
      >
        {' '}
        /{' '}
      </Span>
      <Label
        weight={isEn ? '400' : '700'}
        underline={!isEn}
        data-hoverable={isEn}
        onClick={() => (isEn ? setLang('fr') : null)}
        selectable={false}
        css={`
          cursor: none;
        `}
      >
        FR
      </Label>
    </div>
  );
};

LangSelector.propTypes = {};

export default LangSelector;
