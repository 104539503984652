import styled from 'styled-components';
import Text from '../Text';
import { getRand } from '../../../helpers/util';

export const Wrapper = styled.div`
  margin: 16px;
  display: inline-block;
`;

export const Word = styled(Text)`
  margin-bottom: 16px;
  white-space: nowrap;
`;

export const Letter = styled(Text).attrs(({ index, colour }) => ({
  style: {
    top: getRand(6) + index * 4,
    left: getRand(6),
    color: colour,
  },
}))`
  display: inline-block;
  margin: 5px;
  position: relative;
`;
