/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { useUiStore, usePlayerStore, useLangStore } from '../../store';
import { useInterval } from '../../hooks/TimerHooks';
import config from '../../../config';

function SEO({ description, meta, title, metaImg: imgSrc, noindex }) {
  const loaderFinished = useUiStore(({ loaderFinished: loaderIsFinished }) => loaderIsFinished);
  const currentLang = useLangStore(store => store.currentLang);
  const [newTitle, setTitle] = useState(config.name);
  const isLoading = usePlayerStore(({ isLoading: loading }) => loading);
  const uiLoading = !loaderFinished || isLoading;

  useInterval(
    () => {
      setTitle(
        newTitle
          ?.split('')
          ?.map(v => (Math.round(Math.random()) ? v.toUpperCase() : v.toLowerCase()))
          ?.join(''),
      );
    },
    !loaderFinished || isLoading ? 50 : null,
  );

  const { site, strapiSiteMeta } = useStaticQuery(
    graphql`
      query siteMeta {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        strapiSiteMeta {
          description_en
          description_fr
          site_image {
            publicURL
          }
        }
      }
    `,
  );

  const metaDescription =
    description || strapiSiteMeta[`description_${currentLang}`] || site.siteMetadata.description;

  const metaImg = imgSrc
    ? `https://looseantenna.fm${imgSrc}`
    : `https://looseantenna.fm/${strapiSiteMeta?.site_image?.publicURL}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: currentLang,
      }}
      title={uiLoading ? `\\\\ ${newTitle}` : title}
      titleTemplate={uiLoading ? '%s' : `%s \\\\ ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title ? `${title} \\\\ ${site.siteMetadata.title}` : site.siteMetadata.title,
        },
        {
          property: 'og:url',
          content: 'https://looseantenna.fm',
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImg,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:image',
          content: `${metaImg}`,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        (noindex ? {
          name: 'robots',
          content: 'noindex',
        } : {}),
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  metaImg: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
