import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { colours, textSizes } from '../../../assets/theme';

import { useInterval } from '../../../hooks/TimerHooks';

import config from '../../../../config';

import { Wrapper, Letter, Word } from './styles';

const Logo = () => {
  const theme = useContext(ThemeContext);
  const splitName = config.name.split(' ');
  const [isHovering, setIsHovering] = useState(false);
  const [key, setKey] = useState(110);

  useInterval(
    () => {
      setKey(Math.floor(Math.random() * 1000));
    },
    isHovering ? 230 : null,
  );

  return (
    <Wrapper onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      {splitName.map((word, i) => (
        <Word as="p" isLast={i === splitName.length}>
          {word.split('').map((letter, index) => (
            <Letter
              weight="600"
              size={textSizes.md}
              index={index}
              // randomSeed={`${i}-${key}`}
              key={`${letter}-${index}`}
              selectable={false}
              colour={Math.random() >= 0.3 ? theme?.foreground : theme?.highlight}
            >
              {letter}
            </Letter>
          ))}
        </Word>
      ))}
    </Wrapper>
  );
};

Logo.propTypes = {};

export default Logo;
