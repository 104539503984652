import React from 'react';
import { withTheme } from 'styled-components';

import Logo from '../../atoms/Logo';
import { H3 } from '../../atoms/Text';
import Link from '../../atoms/Link';
import { colours } from '../../../assets/theme';
import CrossSvg from '../../icons/Cross';

import { Block, PositionedLangSelector as LangSelector, Row } from './styles';
import { string } from 'prop-types';

const Header = withTheme(({ title, backTo = '/', theme }) => {
  return (
    <Row justify="space-between" align="center">
      <Block>
        <Link to="/">
          <Logo />
        </Link>
      </Block>
      <Block hideMobile>{title && <H3 align="center">{title}</H3>}</Block>
      <Block isLast>
        {title && (
          <Link to={backTo}>
            <CrossSvg colour={theme?.foreground ?? colours.white} />
          </Link>
        )}
      </Block>
      <LangSelector />
    </Row>
  );
});

Header.propTypes = {
  title: string,
};

export default Header;
